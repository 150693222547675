/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useRef, useState } from 'react';
import { useStickyBox } from 'react-sticky-box';
import { Scrollspy } from '@makotot/ghostui';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Button, Community, Layout, Modal, SEO } from '@/components';

export const Head = ({ location }) => {
  const title = 'Coworking Spaces';
  const description =
    'Njano offers a set of customizable tools that will help you run your coworking space operations effectively';
  return <SEO title={title} description={description} location={location} />;
};

const CoworkingSpacesPage = () => {
  const sectionRefs = [useRef(null), useRef(null), useRef(null)];
  const stickyRef = useStickyBox({ offsetTop: 120, offsetBottom: 20 });
  const [showModal, setShowModal] = useState(false);

  return (
    <Layout logo dark>
      <div className="grid grid-cols-2 py-[5.25rem] px-4 lg:px-[8.75rem] space-y-16">
        <div className="lg:col-span-1 lg:mr-24 lg:ml-16 flex-row items-start justify-center col-span-2">
          <p className="mr-12 mb-12 font-kanit text-5xl font-extralight leading-[3.75rem] text-[#E6E6E6]">
            Our best features for your optimal project and set.
          </p>
          <p className="mt-12 mb-14 font-content text-[2.25rem] font-bold leading-10">
            Njano offers a set of customizable tools that will help you run your coworking space operations effectively.
          </p>
          <Button
            onClick={() => {
              setShowModal(true);
            }}
            className="!block mx-auto lg:mx-0 lg:inline"
          >
            Get started
          </Button>
        </div>
        <div className="lg:col-span-1 lg:mt-0 flex items-center justify-center col-span-2 mt-16">
          <StaticImage
            className="max-w-lg"
            src="../images/header-home.png"
            alt=""
            formats={['auto', 'webp', 'avif']}
            placeholder="dominantColor"
            layout="constrained"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 py-[5.25rem] px-4 lg:px-[8.75rem]">
        <div className="items-center col-span-1">
          <h2 className="font-heading lg:text-6xl mb-8 text-4xl font-bold leading-normal text-center">Dashboard</h2>
          <p className="mb-8 text-center font-content text-[1.625rem] font-bold lg:mx-32">
            All-in-one Dashboard! Start your day with a dynamic and thorough overview of your space. The dashboard
            allows you to track and visualize all your important data instantly.
          </p>
        </div>
      </div>
      <div className="pb-9 lg:pl-8 xl:pl-24 lg:pr-0 px-4">
        <div className="lg:grid-cols-4 grid grid-cols-2">
          <div className="col-span-4 m-8 flex flex-col items-center justify-start rounded-md bg-[#FFE7AA] p-6 lg:col-span-2 xl:col-span-1 lg:py-4 lg:px-2 cursor-pointer hover:-translate-y-4 transition-all duration-500 ">
            <StaticImage
              src="../images/financial-icon.png"
              alt=""
              formats={['auto', 'webp', 'avif']}
              placeholder="blurred"
              layout="fixed"
              className="size-32"
              width={128}
            />
            <div className="relative px-8 py-4">
              <h3 className="my-8 text-center font-heading text-[1.75rem] font-bold text-darkGray h-6 lg:h-auto">
                Financial Management
              </h3>
              <p className="text-normal font-content font-bold text-[#969696]">
                Keep an eye on your numbers and get real-time data and visual representations of the financial health of
                your space as well as smart and thorough financial management tools.
              </p>
            </div>
          </div>
          <div className="col-span-4 m-8 flex flex-col items-center justify-start rounded-md bg-[#E9F0FF] p-6 lg:col-span-2 xl:col-span-1 lg:py-4 lg:px-2 cursor-pointer hover:-translate-y-4 transition-all duration-500 ">
            <StaticImage
              src="../images/community-icon.png"
              alt=""
              formats={['auto', 'webp', 'avif']}
              placeholder="blurred"
              layout="fixed"
              className="size-32"
              width={128}
            />
            <div className="relative px-8 py-4">
              <h3 className="my-8 text-center font-heading text-[1.75rem] font-bold text-darkGray h-6 lg:h-auto">
                Community Management
              </h3>
              <p className="text-normal font-content font-bold text-[#969696]">
                Your team and space users can easily communicate through private messaging, social circles, and feed
                posts.
              </p>
            </div>
          </div>
          <div className="col-span-4 m-8 flex flex-col items-center justify-start rounded-md bg-[#FFE7AA] p-6 lg:col-span-2 xl:col-span-1 lg:py-4 lg:px-2 cursor-pointer hover:-translate-y-4 transition-all duration-500 ">
            <StaticImage
              src="../images/reporting-icon.png"
              alt=""
              formats={['auto', 'webp', 'avif']}
              placeholder="blurred"
              layout="fixed"
              className="size-32"
              width={112}
            />
            <div className="relative px-8 py-4">
              <h3 className="my-8 text-center font-heading text-[1.75rem] font-bold text-darkGray h-6 lg:h-auto">
                Reporting Management
              </h3>
              <p className="text-normal font-content font-bold text-[#969696]">
                Now, you can easily build a thorough and detailed report with all your information at hand.
              </p>
            </div>
          </div>
          <div className="col-span-4 m-8 flex flex-col items-center justify-start rounded-md bg-[#E9F0FF] p-6 lg:col-span-2 xl:col-span-1 lg:py-4 lg:px-2 cursor-pointer hover:-translate-y-4 transition-all duration-500 ">
            <StaticImage
              src="../images/incubation-icon.png"
              alt=""
              formats={['auto', 'webp', 'avif']}
              placeholder="blurred"
              layout="fixed"
              className="size-32"
              width={128}
            />
            <div className="relative px-8 py-4">
              <h3 className="my-8 text-center font-heading text-[1.75rem] font-bold text-darkGray h-6 lg:h-auto">
                Internal Management
              </h3>
              <p className="text-normal font-content font-bold text-[#969696]">
                This is your go-to feature to run your daily operations. Here, you can access the user-base, events,
                manage room booking and much more!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 py-[5.25rem] px-4 lg:mt-32 lg:px-[8.75rem]">
        <Scrollspy sectionRefs={sectionRefs}>
          {({ currentElementIndexInViewport }) => (
            <>
              <div className="lg:visible lg:col-span-1 lg:flex items-start invisible hidden col-span-2">
                <aside ref={stickyRef}>
                  <div className="pt-8">
                    <h4
                      className={`cursor-pointer font-heading transition-all text-[1.875rem] font-normal text-secondary ${
                        currentElementIndexInViewport !== 0 ? 'opacity-60' : ''
                      }`}
                      onClick={() => navigate('#1')}
                    >
                      Internal Management
                    </h4>
                    {currentElementIndexInViewport === 0 ? (
                      <div className="my-8 space-y-8">
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-white hover:text-secondary"
                          href="#1"
                        >
                          Space user management
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-white hover:text-secondary"
                          href="#2"
                        >
                          Events Management
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-white hover:text-secondary"
                          href="#3"
                        >
                          Lost and found Management
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-white hover:text-secondary"
                          href="#4"
                        >
                          Claims management
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-white hover:text-secondary"
                          href="#5"
                        >
                          Rooms Booking management
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-white hover:text-secondary"
                          href="#6"
                        >
                          Front Desk
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-white hover:text-secondary"
                          href="#7"
                        >
                          Surveys
                        </a>
                      </div>
                    ) : null}
                    <h4
                      className={`cursor-pointer font-heading transition-all text-[1.875rem] font-normal text-secondary ${
                        currentElementIndexInViewport !== 1 ? 'opacity-60' : ''
                      }`}
                      onClick={() => navigate('#8')}
                    >
                      Financial Management
                    </h4>
                    {currentElementIndexInViewport === 1 ? (
                      <div className="my-8 space-y-8">
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-white hover:text-secondary"
                          href="#8"
                        >
                          Sales
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-white hover:text-secondary"
                          href="#9"
                        >
                          Purchases
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-white hover:text-secondary"
                          href="#10"
                        >
                          Assets
                        </a>
                      </div>
                    ) : null}
                    <h4
                      className={`cursor-pointer font-heading transition-all text-[1.875rem] font-normal text-secondary ${
                        currentElementIndexInViewport !== 2 ? 'opacity-60' : ''
                      }`}
                      onClick={() => navigate('#11')}
                    >
                      Community Management
                    </h4>
                    {currentElementIndexInViewport === 2 ? (
                      <div className="my-8 space-y-8">
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-white hover:text-secondary"
                          href="#11"
                        >
                          Circles & Feed
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-white hover:text-secondary"
                          href="#12"
                        >
                          Messaging
                        </a>
                        <a
                          className="block cursor-pointer font-heading text-[1.6rem] font-normal text-white hover:text-secondary"
                          href="#13"
                        >
                          People & startups
                        </a>
                      </div>
                    ) : null}
                  </div>
                </aside>
              </div>
              <div className="lg:col-span-1 lg:mx-8 col-span-2 space-y-24">
                <div ref={sectionRefs[0]} className="snap-y space-y-24">
                  <div className="space snap-center pt-20" id="1">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6">
                      Space user management
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      Do you have an issue storing your member information? Not anymore! Check your user data, update
                      them, and assign plans all in one place. You can customize your membership plans, adjust the daily
                      and hourly access and the services offered with each one.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-1.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space snap-center pt-20" id="2">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6">
                      Events Management
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      Keep your members updated and engaged! Bring your event to life with minimal effort. Your users
                      can learn all about what you have to offer through their dashboards and can sign up in just a few
                      clicks. You can even create different types of tickets and share your event with your network.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-2.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space snap-center pt-20" id="3">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6">
                      Lost and found Management
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      An effective way to deal with lost property! Losing an item is a delicate matter and a recurring
                      issue that can disturb the environment in your space. With Njano, you will get a centralized feed
                      allowing your users to share announcements and interact if they lost or found an item.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-3.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space snap-center pt-20" id="4">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6">
                      Claims management
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      You wanna report an issue? Njano displays this feature for users to communicate complaints related
                      to equipment, wifi, temperature,.. and many others for managers to fix them and track the solving
                      progress of each one.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-4.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space snap-center pt-20" id="5">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6">
                      Rooms Booking management
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      Experience a flexible and customizable room booking process for you and your space users. You will
                      be able to add room details, book them, and allow your space users to book through a built-in
                      calendar.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-5.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space snap-center pt-20" id="6">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6">
                      Front Desk
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      Are you expecting a guest? Will an order be delivered to you soon? Take your Frontdesk operations
                      to the next level. You will easily notify your space users of incoming guests and deliveries along
                      with assigning space tours to your staff or allow them to announce their expected visitors.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-6.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space snap-center pt-20" id="7">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6">
                      Surveys
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      Eager to collect your users feedback ? Njano offers you a set of tools to create your customized
                      surveys. You can send them directly to your users and review them afterwards .
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-7.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                </div>
                <div ref={sectionRefs[1]} className="space-y-24">
                  <div className="space snap-center pt-20" id="8">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6">
                      Sales
                    </h3>
                    <p className="mb-8 font-content text-[1.875rem] font-bold text-secondary">
                      Creating an invoice item has never been easier!
                    </p>
                    <p className="font-content mb-8 text-xl">
                      You can easily create invoice items, categorize them into goods or services, and even add the
                      appropriate taxes. These items will be automatically displayed in a dropdown menu ensuring a quick
                      creation of estimates and invoices.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-8.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space snap-center pt-20" id="9">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6">
                      Purchases
                    </h3>
                    <p className="mb-8 font-content text-[1.875rem] font-bold text-secondary">
                      Keep an eye on your purchases and view your suppliers database in one place.
                    </p>
                    <p className="font-content mb-8 text-xl">
                      Your expenses are also categorized through the tracking accounts that will show you real-time data
                      and visual representations of the financial health of your space.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-9.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space snap-center pt-20" id="10">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6">
                      Assets
                    </h3>
                    <p className="mb-8 font-content text-[1.875rem] font-bold text-secondary">
                      Carry all your assets at your fingertips!
                    </p>
                    <p className="font-content mb-8 text-xl">
                      Managing your assets doesn’t have to be a struggle anymore. You will always get real-time
                      visibility into the state of your equipment, whether they are checked-in, checked-out, lost,
                      damaged, or in perfect condition.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-10.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                </div>
                <div ref={sectionRefs[2]} className="space-y-24">
                  <div className="space snap-center pt-20" id="11">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6">
                      Circles & Feed
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      Create or join circles that match your interests and get an overview about them all in your Feed!
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-11.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space snap-center pt-20" id="12">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6">
                      Messaging
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      Communicate privately with your colleagues and space users or connect with external Njano users
                      through a live chat system.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-12.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="space snap-center pt-20" id="13">
                    <h3 className="text-center font-heading text-[2.8rem] leading-tight font-bold lg:text-left mb-6">
                      People & startups
                    </h3>
                    <p className="font-content mb-8 text-xl">
                      Get to know more about your community members and build strong collaborations For a long-term
                      community bond.
                    </p>
                    <StaticImage
                      className="!block w-4/5 mx-auto lg:w-auto mt-8 lg:mt-0"
                      src="../images/coworking-13.png"
                      alt=""
                      formats={['auto', 'webp', 'avif']}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Scrollspy>
      </div>
      <Community />
      {showModal ? <Modal open={showModal} setOpen={setShowModal} /> : null}
    </Layout>
  );
};

export default CoworkingSpacesPage;
